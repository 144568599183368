body,
html {
  width: 100%;
  height: 100%;
}

*,
:after,
:before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.375;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  font-size: 100%;
  color: #333;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-family: Arial, sans-serif;
  line-height: 18px;
  background-color: #f2f2f2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #484848;
  font-weight: 500;
}

p {
  white-space: pre-line;
}

hr {
  width: 100%;
}

a {
  text-decoration: none;
}
